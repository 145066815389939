import React, { useEffect } from "react"
import * as signupnowStyles from "../styles/signupnow.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"

const SignUpNow = () => {
  return (
    <Layout>
      <SEO title="Sign up now" />
      <section className={signupnowStyles.section}>
        <h1>Registration Form</h1>
        <div className={signupnowStyles.container}>
          <h3>Tennis Lessons On-site and Off-site!</h3>
          <h3>
            Moussa Drame Tennis PO Box 1193 Shelter Island Heights, NY 11965.
          </h3>
        </div>

        <div className={signupnowStyles.box}>
          <h6>
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSePQWUqEH2EAcDg9UdSsJg-Mv_r4SOU5TqvunWXOWkAkVkzyw/viewform?pli=1"
            >
              Shelter Island Tennis Private Lessons : At Our Courts
            </a>
          </h6>
        </div>
        <div className={signupnowStyles.box}>
          <h6>
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdfoBa_au8zUFxeJUsNRt1IWPprui0SIYk9IAfD12trbJBHWw/viewform"
            >
              Shelter Island Private Lessons : At Your Own Court
            </a>
          </h6>
        </div>

        <div className={signupnowStyles.box}>
          <h6>
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLScfjU17jM_dFIpODe2To_dbR8Jvba7njkjY8kRxRSP5Cezh-g/viewform"
            >
              Shelter Island Semi-Private & Group Lessons: At Our Courts or At
              Your Own Court
            </a>
          </h6>
        </div>
        <div className={signupnowStyles.box}>
          <h6>
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSfu1vPOcAKI59reovLJIIPMx1aIXOUcbtH6FReUMus8as_H4Q/viewform"
            >
              Shelter Island ALL-Sports Junior Program
            </a>
          </h6>
        </div>
        <div className={signupnowStyles.box}>
          <h6>
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdeSdLGT0Lpvi82HmJj7gd1y2VlVurKqaIt3V9kvF4qryIC1w/viewform"
            >
              Shelter Island Pee-Wee Tennis
            </a>
          </h6>
        </div>
        <div className={signupnowStyles.box}>
          <h6>
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSezsjdnHYwmvvu8i_BWno63L3TgSPOs8zKRO3ySL5avpiSTbQ/viewform"
            >
              Shelter Island Sunset Tennis Clinics
            </a>
          </h6>
        </div>
      </section>
    </Layout>
  )
}

export default SignUpNow
